@import "_custom-properties.css";


@media (min-width: 1499px) {
	.container {
		max-width: var(--content-width-sm);
	}

	.css_prefix .container {
		max-width: var(--content-width);
	}
}

@media (min-width: 1300px) {
	.container {
		max-width: var(--content-width-sm);
	}

	.css_prefix .container {
		max-width: var(--content-width);
	}
}

.container-fluid {
	padding: 0 30px;
}

body .container {
	max-width: var(--content-width-sm);
}

body .css_prefix .container,
body .elementor-section.elementor-section-boxed>.elementor-container {
	max-width: var(--content-width);
}

.content-area .site-main {
	padding: 130px 0;
	overflow: hidden;
}

@media (max-width: 1024px) {
	.content-area .site-main {
		padding: 70px 16px;
	}
}

@media (max-width: 767px) {
	.content-area .site-main {
		padding: 40px 16px;
	}
}

/*---------------------------------------------------------------------
			  Scrollbar
  -----------------------------------------------------------------------*/
  /* width */
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}
  /* Track */
::-webkit-scrollbar-track { 
	border-radius: 4px;
}
   
  /* Handle */
::-webkit-scrollbar-thumb {
	background: var(--color-theme-primary); 
	border-radius: 4px;
}

.yScroller::-webkit-scrollbar {
	display: none;
}

.yScroller {
	scrollbar-width: none;
}


/*-------------------------  Back to Top  -------------------------*/
#back-to-top .top {
	position: fixed;
	margin: 0px;
	color: var(--color-theme-white);
	background: var(--color-theme-primary);
	position: fixed;
	bottom: 65px;
	right: 30px;
	z-index: 99;
	font-size: 26px;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	border-radius: var(--border-radius);
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

#back-to-top .top svg {
	color: var(--color-theme-white);
}

#back-to-top .top:hover {
	background: var(--color-theme-secondary);
}


/*-------------------  Loader  ---------------------------------------*/
#loading {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--global-body-bgcolor);
	z-index: 9999;
}

/*---------------------------------------------------------------------
   Breadcrumb style 1 2 and 3 4 5
  -----------------------------------------------------------------------*/
.breadcrumb li {
	display: inline-block;
	word-break: break-word;
}

.css_prefix-breadcrumb {
	padding: 130px 0;
	background: var(--global-body-lightcolor);
}

.css_prefix-breadcrumb-two {
	position: relative;
	z-index: 2;
}

.css_prefix-breadcrumb-one {
	display: block;
	padding: 130px 0;
	z-index: 9;
	position: relative;
}

.css_prefix-breadcrumb-one ol li a,
.css_prefix-breadcrumb-one ol li {
	color: var(--color-theme-primary);
	text-decoration: none;
	font-weight: var(--font-weight-regular);
	font-family: var(--highlight-font-family);
	letter-spacing: 3px;
}

.css_prefix-breadcrumb-one ol li a:hover {
	color: var(--color-theme-secondary);
}

.css_prefix-breadcrumb-one ol li {
	list-style: none;
	display: inline-block;
	position: relative;
}

.breadcrumb-ui::before, .breadcrumb-bg::before {
	position: absolute;
	left: 0;
	right: 0;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
}

.css_prefix-breadcrumb-one .breadcrumb-item+.breadcrumb-item::before {
    content: "\f105";
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    position: absolute;
    padding: 0;
    left: -5px;
    top: 0px;
    color: inherit;
    font-size: 14px;
    bottom: 0;
}
.css_prefix-breadcrumb-one .breadcrumb-item.active:before{
    top:3px;
}

.breadcrumb-item+.breadcrumb-item {
	padding-left: 10px;
	margin-left: 10px;
}

.css_prefix-breadcrumb-one .breadcrumb {
	position: relative;
	display: inline-block;
}

.css_prefix-breadcrumb-one .breadcrumb-item.active {
	color: var(--color-theme-primary);
	position: relative;
	font-weight: var(--font-weight-regular);
}

.css_prefix-breadcrumb-one .breadcrumb-item:last-child {
	color: var(--color-theme-primary);
	font-weight: var(--font-weight-regular);
	line-height: normal;
}

.css_prefix-breadcrumb-one ol {
	background: transparent;
	padding: 0;
	margin-bottom: 0;
}

.css_prefix-breadcrumb-one .title {
	margin-bottom: 16px;
}

.css_prefix-breadcrumb-two .breadcrumb {
	display: flex;
	align-items: center;
	line-height: normal;
	justify-content: center;
	flex-wrap: wrap;
}

.css_prefix-breadcrumb-two ol li {
	display: inline-block;
}

.css_prefix-breadcrumb-three .breadcrumb {
	margin-top: 0;
}

.css_prefix-breadcrumb-three .css_prefix-breadcrumb-one {
	padding: 0;
}

.css_prefix-breadcrumb-three {
	padding: 45px 0;
}

.breadcrumb-bg,
.breadcrumb-video,
.video-css_prefix-bg-over {
	position: relative;
	overflow: hidden;
}

.breadcrumb-bg video,
.breadcrumb-bg #video-background,
.breadcrumb-video video,
.video-css_prefix-bg-over video,
.video-breadcrumb-bg #video-background,
.video-css_prefix-bg-over #video-background {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: inline-block;
	width: 100%;
}

.breadcrumb-bg.css_prefix-over-dark-80::before,
.breadcrumb-ui::before {
	z-index: 0;
}

.css_prefix-breadcrumb-one {
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-size: cover !important;
	background: var(--color-theme-dim-white);
}


.css_prefix-breadcrumb-one .main-shap-box .shap-one {
	left: -130px;
	z-index: 3;
	top: -150px;
}

.css_prefix-breadcrumb-one .main-shap-box .shap-two {
	width: 90px;
	height: 90px;
}

/* responsive breadcrumb */

@media(max-width : 1024px) {
	.css_prefix-breadcrumb-one {
		padding: 70px 0;
	}

}

@media(max-width : 767px) {
	.css_prefix-breadcrumb-one {
		padding: 40px 0;
	}

}