@import "_custom-properties.css";

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
/* stylelint-disable */
a {
	color: var(--color-link);
}
/* stylelint-enable */

a:visited {
	color: var(--color-link-visited);
}

a:hover,
a:focus,
a:active {
	color: var(--color-link-active);
}

a:focus {
	outline: thin dotted;
}

a:hover,
a:active {
	outline: 0;
}
