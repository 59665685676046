@import "_custom-properties.css";

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
/* stylelint-disable */
html {
	box-sizing: border-box;
}
/* stylelint-enable */

/**
 * Inherit box-sizing to make it easier to change the property for
 * components that leverage other behavior; see
 * http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
	box-sizing: inherit;
}

a:focus {
	color: var(--color-theme-primary);
	text-decoration: none !important;
}

a:hover {
	color: var(--color-theme-primary);
	text-decoration: none;
}
:focus {
	outline: none;
}

a{
	outline: none;
}

p {
	margin: 15px 0;
	line-height: inherit;
}

/* stylelint-disable */
hr {
	background-color: var(--border-color-dark);
	border: 0;
	height: 1px;
	margin: 0;
	padding: 0;

}
/* stylelint-enable */

ul,
ol {
	padding-left: 25px;
	margin-bottom: 1em;
}

ol ol {
	padding-left: 25px;
}

/* Definition Lists */
dd {
	margin: 0 1.5em 1.5em;
}

dl dd {
	margin-bottom: 15px;
}

dl dd:last-child {
	margin-bottom: 0;
}

li > ul,
li > ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

/* stylelint-disable */
img {
	max-width: 100%;
	height: auto;
}
/* stylelint-enable */


/* Table */
table {
	border: 1px solid var(--color-theme-white);
	width: 100%;
	margin-bottom: 20px;
}

table td,
table th {
	border: 1px solid var(--global-body-lightcolor);
	padding: 5px 8px;
	text-align: center;
}

/* inputs */

input[type="checkbox"] {
	width: auto;
	margin-right: 10px;
	line-height: 2;
	height: 32px;
}
input,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	width: 100%;
	float: left;
	padding: 0 15px;
	height: 60px;
	line-height: 60px;
	background: var(--color-theme-white);
	border: 1px solid var(--color-theme-border);
	border-radius: var(--border-radius);
-webkit-border-radius: var(--border-radius);
-moz-border-radius: var(--border-radius);
-ms-border-radius: var(--border-radius);
-o-border-radius: var(--border-radius);
	color: var(--global-font-color);
	transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
}


select {
	border: 1px solid var(--color-theme-white);
	background: var(--global-body-bgcolor);
	line-height: 48px;
	height: 48px;
	padding: 0 10px;
	width: 100%;
	color: var(--global-font-color);
	border-radius: var(--border-radius);
	-webkit-border-radius: var(--border-radius);
	-moz-border-radius: var(--border-radius);
	-ms-border-radius: var(--border-radius);
	-o-border-radius: var(--border-radius);
	transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
}

textarea {
	padding: 15px;
	height: 150px;
	line-height: var(--font-line-height-body);
}

input::-webkit-input-placeholder {
	color: inherit;
}

input::-moz-placeholder {
	color: inherit;
}

input:-ms-input-placeholder {
	color: inherit;
}

textarea::-webkit-input-placeholder {
	color: inherit;
}

textarea::-moz-placeholder {
	color: inherit;
}

textarea:-ms-input-placeholder {
	color: inherit;
}

input[type="email"]::-webkit-input-placeholder {
	color: inherit;
}

input[type="email"]::-moz-placeholder {
	color: inherit;
}

input[type="email"]:-ms-input-placeholder {
	color: inherit;
}

input[type="text"]:focus,
input:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus,
select:focus {
	border-color: var(--color-theme-primary);
	box-shadow: none;
	outline: none;
}

embed,
iframe,
object {
	max-width: 100%;
	width: 100%;
}

iframe {
	border: none;
}
