@import "_adminbar.css";
/* header */

header.header-default.header-up,
header.header-default.header-down, 
.ehf-header #masthead.header-up, 
.ehf-header #masthead.header-down {
    position: fixed;
    left: 0;
    right: 0;
	top: 0;
    background: var(--color-theme-white);
	box-shadow: var(--global-box-shadow);
    z-index: 99;
	transition: all 0.45s ease 0s;
	-webkit-transition: all 0.45s ease 0s;
	-moz-transition: all 0.45s ease 0s;
	-o-transition: all 0.45s ease 0s;
}

.admin-bar header.header-default.header-up,
.admin-bar.ehf-header #masthead.header-up {
	top: 30px;
}

header.header-default.header-down, .admin-bar header.header-default.header-down,
.ehf-header #masthead.header-down, .admin-bar.ehf-header #masthead.header-down{
	top: -100px;	
}
/* menu */
header .navbar-brand {
    margin-right: 0;
	padding: 15px 0;
}
header .navbar-collapse{
	margin-left: 20px;
}

.navbar-brand .logo {
    width: 150px;
}

/*** ESSENTIAL STYLES ***/
.sf-menu, .sf-menu * {
	margin: 0;
	padding: 0;
	list-style: none;
}
.sf-menu li {
	position: relative;
}
.sf-menu ul {
	position: absolute;
	display: none;
	top: 100%;
	left: 0;
	z-index: 99;
}
.sf-menu > li {
	float: left;
	padding: 15px 0;
}
.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
	display: block;
}

.sf-menu a {
	display: block;
	position: relative;
}
.sf-menu ul ul {
	top: 0;
	left: 100%;
}


/*** DEMO SKIN ***/

.sf-menu {
	float: left;
	margin-bottom: 0;
}
.sf-menu ul.sub-menu {
	padding:15px;
	background: var(--color-theme-white);
	box-shadow: var(--global-box-shadow);
	width: 300px; /* no auto sub width for IE7, see white-space comment below */
	border-radius: var(--border-radius-box);
}

.sf-menu ul.sub-menu a{
	padding: 10px 15px;
	color: var(--global-font-color);
	margin-bottom: 5px;
}
.sf-menu ul.sub-menu li:last-child a{
	margin-bottom: 0px;
}
 .sc_layouts_menu_nav li li.menu-item-has-children > a {
    padding-right: 35px;
}
.sf-menu >li > a {
	text-transform:capitalize;
}
.sf-menu a {
	text-transform: capitalize;
    color: var(--global-font-title);
    font-family: var(--global-font-family);
    font-weight: var(--font-weight-regular);
	padding: 18px 15px;
	text-decoration: none;
	zoom: 1; /* IE7 */
	transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

 .sf-menu > li > ul ul {
    top: 0px;
    margin: 0 0 0 20px;
}

.sf-menu li {	
	transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.sf-menu li:hover > a,
.sf-menu li.sfHover > a{
	color: var(--color-theme-primary);
	-webkit-transition: none;
	transition: none;
}

.sf-menu ul li:hover > a,
.sf-menu ul li.sfHover > a{
	padding-left: 15px;
	transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
	background-color: var(--color-theme-primary);
	color: var(--color-theme-white);
	border-radius: var(--border-radius-box);
}

.sf-menu  li.current-menu-item > a,
.sf-menu li.current-menu-ancestor > a,
.sf-menu  ul li .sub-menu li.current-menu-item > a,
.sf-menu  ul > li.menu-item.current-menu-parent>a,
.sf-menu  ul li.current-menu-parent > a{
	color: var(--color-theme-primary);
 }
.sf-arrows li.current-menu-ancestor > .sf-with-ul:after{
	border-top-color: var(--color-theme-primary);
}

.sf-arrows .sf-menu ul li .sub-menu li.current-menu-parent>a .sf-with-ul:after{
	border-left-color: var(--color-theme-primary);
	border-top-color: transparent;
}
/* custome */
header .sf-menu.navbar-nav  ul.sub-menu li.current-menu-item a
{
	background-color: var(--color-theme-primary);
	color: var(--color-theme-white);
	border-radius: var(--border-radius-box);
}
header .sub-menu > li.sfhover > .sf-with-ul {
    color:var(--color-theme-white);
	background-color: var(--color-theme-primary);
}
header .sf-menu.navbar-nav ul > li.menu-item-object-custom.current-menu-ancestor a
header .sf-menu.navbar-nav ul > li:hover.menu-item-object-custom a{
	background-color: var(--color-theme-primary);
	color: var(--color-theme-white);
	border-radius: var(--border-radius-box);
}
.sf-menu.navbar-nav  ul > li.current-menu-ancestor .sf-with-ul,
.sf-menu.navbar-nav  ul > li:hover .sf-with-ul {
	background-color: var(--color-theme-primary);
	color: var(--color-theme-white);
	border-radius: var(--border-radius-box);
}

/*  */

.sf-arrows .sf-with-ul {
	padding-right: 25px;
}

.sf-arrows .sf-with-ul:after {
    content: "\f107";
	font-size: 14px;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    position: absolute;
    top: 50%;
	right: 4px;
    display: inline-block;
    transform: translate(-50%,-50%);

}

.sf-menu li > .sub-menu  .sf-with-ul:after{
	right: 25px;
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
	border-top-color: var(--color-theme-primary); /* IE8 fallback colour */
}

/* styling for right-facing arrows */
.sf-arrows ul .sf-with-ul:after {
	content: "\f0da";
}
.sf-arrows ul .sf-with-ul:after
.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
	border-left-color: var(--color-theme-primary);
}

.sf-menu ul.sub-menu li a:hover:after , .sf-menu ul li:hover>a:after ,.sf-arrows ul li:hover .sf-with-ul:after{
	color:  var(--color-theme-white);
} 

/* header over */
.header-over header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
	background: var(--color-theme-white);
}

.admin-bar .header-over header{
	top: 30px;
}

.header-over .css_prefix-breadcrumb-one{
	padding: 200px 0 130px;
}

/* Animation */
@-webkit-keyframes fadeInAnimation {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes fadeInAnimation {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@-webkit-keyframes fadeOutAnimation {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

@keyframes fadeOutAnimation {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

@-webkit-keyframes fadeInAnimationScale {
	0% {opacity: 0; -webkit-transform:scale(0.2) rotate(0);}
	100% {opacity: 1; -webkit-transform:scale(1) rotate(180deg);}
}

@keyframes fadeInAnimationScale {
	0% {opacity: 0; transform:scale(0.2) rotate(0);}
	100% {opacity: 1; transform:scale(1) rotate(180deg);}
}

@-webkit-keyframes fadeOutAnimationScale {
	0% {opacity: 1;  -webkit-transform:scale(1) rotate(180deg);}
	100% {opacity: 0; -webkit-transform:scale(0.2) rotate(0);}
}

@keyframes fadeOutAnimationScale {
	0% {opacity: 1;  transform:scale(1) rotate(180deg);}
	100% {opacity: 0; transform:scale(0.2) rotate(0);}
}

@-webkit-keyframes fadeInRightPanel {
	0% {opacity: 0;	-webkit-transform: translateX(30px) ;}
	100% {opacity: 1; -webkit-transform: translateX(0) ;}
}

@keyframes fadeInRightPanel {
	0% {opacity: 0;	transform: translateX(30px) ;}
	100% {opacity: 1; transform: translateX(0) ;}
}

@-webkit-keyframes fadeOutRightPanel {
	0% {opacity: 1;	-webkit-transform: translateX(0) ;}
	100% {opacity: 0;-webkit-transform: translateX(20px) ;}
}

@keyframes fadeOutRightPanel {
	0% {opacity: 1;	transform: translateX(0) ;}
	100% {opacity: 0; transform: translateX(20px) ;}
}

@-webkit-keyframes fadeInMove {
	0% {opacity: 0; -webkit-transform: scale3d(0, 1, 1);}
	10% {opacity: 1; -webkit-transform: scale3d(0.1, 1, 1); }
	100% {opacity: 1; -webkit-transform: scale3d(1, 1, 1); }
}

@keyframes fadeInMove {
	0% {opacity: 0; transform: scale3d(0, 1, 1); }
	10% {opacity: 1; transform: scale3d(0.1, 1, 1); }
	100% {opacity: 1; transform: scale3d(1, 1, 1); }
}

@-webkit-keyframes fadeOutMove {
	0% {opacity: 0.14; -webkit-transform: scale3d(1, 1, 1); }
	100% {opacity: 0.14; -webkit-transform: scale3d(0, 1, 1); }
}

@keyframes fadeOutMove {
	0% {opacity: 0.14; transform: scale3d(1, 1, 1); }
	100% {opacity: 0.14; transform: scale3d(0, 1, 1); }
}

@-webkit-keyframes fadeOutMove2 {
	0% {opacity: 0.8;  -webkit-transform: scale3d(1, 1, 1); }
	100% {opacity: 0.14;  -webkit-transform: scale3d(0, 1, 1); }
}

@keyframes fadeOutMove2 {
	0% {opacity: 0.8;  transform: scale3d(1, 1, 1); }
	100% {opacity: 0.14; transform: scale3d(0, 1, 1); }
}

@media (max-width: 1400px){
	.css_prefix-header-right ul li a .search-text {
		display: none;
	}
}
@media only screen and (min-width: 1199px) and (max-width: 1300px){
	.sf-menu>li>a {
		text-transform: uppercase;
		font-size: 14px;
		padding: 18px 12px;
	}
}
@media (min-width: 1200px) {
	header.header-default .custom-toggler {
		display: none;
	}
}

@media (max-width: 1199px){
	header.header-default {
		padding: 15px 0;
	}
}

@media (max-width: 782px) {
	.admin-bar header.header-default.header-up {
		top: 45px;
	}
}

@media (max-width: 600px) {
	.admin-bar header.header-default.header-up {
		top: 0;
	}
}