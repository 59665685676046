/**
 * Custom Properties
 * aka CSS variables.
 *
 * @link: https://developer.mozilla.org/en-US/docs/Web/CSS/--*
 **/

:root {

	/* Container */
	--content-width: 1300px;
	--content-width-sm: 1170px;

	/* Typography */
	--global-font-family: 'Poppins', sans-serif;
	--highlight-font-family: 'DM Sans', sans-serif;
	--global-font-size: 1rem;
	--global-font-line-height: 1.4;
	--global-box-shadow: 10px 10px 50px rgb(0 0 0 / 5%);

	/* Fontsize */
	--font-size-small: 0.75rem;
	--font-size-normal: 0.875rem;
	--font-size-body: 1rem;

	--font-size-h1: 4.209rem;
	--font-size-h2: 3.158rem;
	--font-size-h3: 2.369rem;
	--font-size-h4: 1.777rem;
	--font-size-h5: 1.333rem;
	--font-size-h6: 1rem;
	--font-size-xs: 0.75rem;

	/* Line-Height */
	--font-line-height-h1: 80px;
	--font-line-height-h2: 61px;
	--font-line-height-h3: 48px;
	--font-line-height-h4: 38px;
	--font-line-height-h5: 32px;
	--font-line-height-h6: 26px;
	--font-line-height-p: 26px;
	--font-line-height-body: 28px;

	/* Font-Weight */
	--font-weight-body: 400;
	--font-weight-h1: 500;
	--font-weight-h2: 500;
	--font-weight-h3: 500;
	--font-weight-h4: 500;
	--font-weight-h5: 500;
	--font-weight-h6: 500;

	/* font-weight*/
	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semi-bold: 600;
	--font-weight-bold: 700;

	/* Letter spacing */
	--font-letter-spacing-body: 0.02rem;
	--font-letter-spacing-link: 0.02rem;
	--font-letter-spacing-h1: 0.02rem;
	--font-letter-spacing-h2: 0.02rem;
	--font-letter-spacing-h3: 0.02rem;
	--font-letter-spacing-h4: 0.02rem;
	--font-letter-spacing-h5: 0.02rem;
	--font-letter-spacing-h6: 0.02rem;
	--letter-spacing: 0.02rem;
	--letter-spacing-one: 1px;
	--letter-spacing-two: 2px;
	--letter-spacing-three: 3px;
	--letter-spacing-four: 4px;

	/* border-radius */
	--border-radius: 3px;
	--border-radius-box: 5px;
	--border-radius-ten: 0px;
	--border-radius-90: 90px;
	/* z index */
	--z-index-minus: -1;


	/* Colors */
	--global-body-bgcolor: #ffffff;
	--global-body-lightcolor: #f1f3f5;
	--global-font-color: #525f81;
	--global-font-title: #313e5b;
	--global-font-subtitle: #437eeb;
	--global-dark-submenu: #7C859B;
	--global-body-lightbg: #f7f7f7;
	--border-color-light: #f1f3f5;
	--border-color-dark: #262f48;
	--color-link: #437eeb;
	--color-link-visited: #437eeb;
	--color-link-active: #437eeb;
	--color-mobile-menu-bg: #000505;
	--color-footer-top-dark: #000505;
	--color-categoey-bg: #F5F5F5;
	--color-post-sticky-bg: #F9F9F9;


	/* Custom editor colors */
	--color-theme-primary: #437eeb;
	--color-theme-primary-dark: #2A3652;
	--color-theme-primary-light: #F5F7FE;
	--color-theme-secondary: #313e5b;
	--color-theme-secondary-dark: #000303;
	--color-theme-secondary-light: #1A1E1E;
	--color-theme-blue: #437eeb;
	--color-theme-black: #000;
	--color-theme-grey: #95a5a6;
	--color-theme-white: #ffffff;
	--color-theme-white-light: #7C859B;
	--footer-theme-dark: #0E1527;
	--color-theme-dim-white: #eff1fe;
	--color-theme-border:#dee2e6;

	/* Header editor colors */
	--global-font-active: #2870DE;
	--global-font-hover: #2870DE;


}

@media (max-width: 991px) {
	:root {
		--font-size-h1: 3.653rem;
		--font-size-h2: 2rem;
		--font-size-h3: 1.827rem;
		--font-size-h4: 1.700rem;
		--font-line-height-h1: 1.1;
		--font-line-height-h2: 1.1;
		--font-line-height-h3: 1.1;
		--font-line-height-h4: 1.1;
	}
}