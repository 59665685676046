/*--------------------------------------------------------------
# Adminbar css
--------------------------------------------------------------*/


body.admin-bar header ,body.admin-bar .has-sticky.header-up{
	top: 30px;
} 


@media only screen and (max-width:600px){
	/* Static adminbar (Not sticky)*/
	body.admin-bar .has-sticky.header-up{
		top:0;
	}

}

@media only screen and (min-width: 601px) and (max-width: 782px){
	/* Sticky adminbar*/
	
	body.admin-bar header ,body.admin-bar .has-sticky.header-up{
		top: 46px;
	} 
	
}