.css_prefix-btn-container {
    vertical-align: middle;
}

.css_prefix-button {
    color: var(--color-theme-white);
    font-size: var(--font-size-normal);
    font-family: var(--highlight-font-family);
    letter-spacing: var(--letter-spacing-four);
    font-weight: var(--font-weight-body);
    line-height: var(--font-line-height-body);
    background-color: var(--color-theme-primary);
    border-radius: var(--border-radius-box);
    padding: 12px 24px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

.css_prefix-button:hover {
    background: var(--color-theme-secondary);
    color: var(--color-theme-white);
}

.form .cust-button-wrap .cust-button-div a.cust-button {
    color: var(--color-theme-white);
    background: var(--color-theme-primary);
}

.form .cust-button-wrap .cust-button-div a.cust-button:hover {
    background: var(--color-theme-secondary);
}

.error-404 .blog-button .css_prefix-button {
    color: var(--color-theme-white);
    background: var(--color-theme-primary);
}

.error-404 .blog-button .css_prefix-button:hover {
    background: var(--color-theme-secondary);
}

button:hover,
button:focus {
    outline: none;
}

.css_prefix-button .text-btn {
    margin-right: 0px;
    position: relative;
}


.css_prefix-button.css_prefix-button-link {
    background: transparent;
    font-size: var(--font-size-normal);
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    padding: 0;
    box-shadow: none;
    letter-spacing: var(--letter-spacing-two);
}

.css_prefix-button .css_prefix-icon-right {
    padding-left: 5px;
}

.css_prefix-button.css_prefix-button-link:hover {
    background: transparent;
    color: var(--global-font-title);
}

.css_prefix-button.css_prefix-button-link:hover span.main {
    background: var(--color-theme-secondary);
}

.css_prefix-button.css_prefix-button-link {
    color: var(--color-theme-primary);
    font-weight: var(--font-weight-bold);
}

.css_prefix-button span.main {
    width: 6px;
    height: 6px;
    background-color: var(--color-theme-secondary);
    display: inline-block;
    z-index: 1;
}

.css_prefix-button:hover span.main {
    background: var(--color-theme-white);
}

.css_prefix-button.css_prefix-button-link:hover span.text-btn {
    color: var(--global-font-title);
}

.button-link i {
    font-size: var(--font-size-body);
    margin-left: 5px;
}