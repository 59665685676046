/*--------------------------   Search Box     ------------------------ */

.css_prefix-shop-btn-holder ul{
	margin: 0;
}

.search-box input[type="search"] {
	height: 40px;
	font-size: 18px;
	display: inline-block;
	border: none;
	border-radius: 5px;
	outline: none;
	padding: 15px 40px 15px 15px;
	width: 0px;
	position: absolute;
	top: -9px;
	right: 0;
	background: none;
	transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
	cursor: pointer;
	z-index: 11;
	margin: -10px 0 0 0;
  }

.search-box input[type="search"]:focus {
	width: 250px;
	z-index: 1;
	cursor: text;
	background: var(--color-theme-white);
	z-index: 9;
	color: rgba(102, 102, 102, 0.6);
  }
  
.search-box {
	position: relative;
  }
  
.search-box .search-submit:hover {
	background: none;
  }

  header .search-form input:focus,
  header .search-form input[type=text]:focus,
  header .search-form input[type=email]:focus,
  header .search-form input[type=search]:focus,
  header .search-form input[type=password]:focus {
	border: none !important;
  }

  button#btn-search-close svg{
	  color: var(--color-theme-white);
  }

  .search-form .search-submit {
	top: 12px;
  }
  
  .search-form .search-submit svg {
	font-size: 24px;
	color: var(--color-theme-white);
  }
  
  .navbar ul.css_prefix-shop-btn-holder ul li a:hover {
	background: transparent;
  }
  
  .search-form .search-submit {
	background: transparent;
	line-height: normal;
  }
  
  .search.search--open {
	pointer-events: auto;
	opacity: 1;
	-webkit-opacity: 1;
	-moz-opacity: 1;
	-o-opacity: 1;
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
  }
  
  header .search {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: rgb(17 17 17 / 95%);
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;
	pointer-events: none;
	opacity: 0;
	-webkit-opacity: 0;
	-moz-opacity: 0;
	-o-opacity: 0;
	transform: translateY(-100%);
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transition: all 0.5s ease-in-out; 
	-moz-transition: all 0.5s ease-in-out; 
	-ms-transition: all 0.5s ease-in-out; 
	-o-transition: all 0.5s ease-in-out; 
	-webkit-transition: all 0.5s ease-in-out; 
  }
  
  .btn--search-close {
	font-size: 1.5em;
	position: absolute;
	top: 1em;
	right: 1.25em;
	display: none;
	color: var(--color-theme-white);
	background: transparent;
	display: block;
  }
  body.admin-bar .btn--search-close{
	top: 1.5em;
}
  
  .btn--search-close:focus {
	outline: 0;
	box-shadow: none;
  }
  
  .search--open .search__form {
	margin: 5em 0;
	width: auto;
	position: relative;
	-webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }

  .search__form::after {
    content: '';
	position: absolute;
    top: auto;
    bottom: -10px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--color-theme-white);
    -webkit-transform: scale3d(0, 1, 1);
    -ms-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
	-webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    -o-animation-delay: 0.8s;
    animation-delay: 0.8s;
    visibility: hidden;
}

.search--open .search__form::after {
	-webkit-animation-name: fadeInMove;
    animation-name: fadeInMove;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
}
  
  .search__input {
	font-family: inherit;
	background: none;
	border: none;
	height: auto;
	font-size: 45px;
	line-height: 1;
	display: inline-block;
	box-sizing: border-box;
	width: 100%;
	padding: 0.05em 0;
	color: var(--color-theme-white);
	border-bottom: 2px solid;
  }
  
  .search__input:focus {
	border: none;
	border-bottom: 2px solid;
  }
  
  .search__input::-webkit-input-placeholder {
	opacity: 0.6;
	color: var(--color-theme-white);
  }
  
  .search__input::-moz-placeholder {
	opacity: 0.6;
	color: var(--color-theme-white);
  }
  
  .search__input:-ms-input-placeholder {
	opacity: 0.6;
	color: var(--color-theme-white);
  }
  
  .search__input::-webkit-search-cancel-button,
  .search__input::-webkit-search-decoration {
	-webkit-appearance: none;
  }
  
  .search__input::-ms-clear {
	display: none;
  }
  
  form.search-form .form-row .has-content~label,
  form.search-form .form-row input:focus~label,
  form.search-form .form-row label {
	top: -90px;
  }
  
  .css_prefix-shop-btn-holder ul li svg {
	font-size: 20px;
	height: 16px;
	width: 20px;
  }
  
  .search-form input {
	height: auto;
	border-radius: 5px;
	border: none;
	background: transparent;
	padding: 0;
	border-bottom: 2px solid #1f2332;
  }
  
  form.search-form .form-row label {
	color: var(--color-theme-white);
	text-align: left;
	font-size: 45px;
	top: -13px;
	margin-bottom: 0;
	left: 0;
	display: block;
	float: left;
	width: 100%;
	margin-bottom: 0px;
	position: absolute;
	pointer-events: none;
	left: 0px;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
  }
  
  .search-form input {
	padding-right: 50px;
	box-shadow: none;
	height: auto;
	border: none;
	background: transparent;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
  }
.search-form{position: relative;}
.search-form .form-search .search-submit {
	position: absolute;
	right: 0;
	top: 0;
	color: var(--body-text);
	cursor: pointer;
	padding: 12px 15px;
	font-size: 18px;
	background: none;
	border: none;
	outline: none;
	box-shadow: none !important;
	margin: 0;
  }
  .search-form .form-search .search-submit:hover{
	  background: var(--color-theme-primary-dark);
  }

  .search_count #btn-search, .search_count #btn-search svg {
	font-family: var(--highlight-font-family);
	color: var(--color-theme-white);
	text-transform: uppercase;
	letter-spacing: 3px;
}
/*  */
header .search_count a {
    height: 45px;
    width: 45px;
    background: var(--color-theme-primary);
    text-align: center;
    line-height: 45px;
    border-radius: 4px;
    display: inline-block;
}
header .search_count a:hover{
	background: var(--global-font-title);
}
header span.search-text {
    display: none;
}
/*  */
.search_count #btn-search:hover, 
.search_count #btn-search:hover svg{
	color: var(--color-theme-white);
}
.css_prefix-header-right {
    display: flex;
    align-items: center;
}



/* search default */
.search_wrap.search-form-default .search-form input {
    border-bottom: 1px solid var(--global-font-title) !important;
    border-radius: 0;
	height: 45px;
	line-height: 45px;
	font-size: 18px;
	padding-right: 30px;
}

.search_wrap .search-form input::placeholder{
	color: var(--global-font-color);
}

.search_wrap.search-form-default .search-form .search-submit svg{
	font-size: 18px;
	color: var(--global-font-color);
}

.search_wrap.search-form-default .search-form .form-search .search-submit{
	padding: 0;
	height: 30px;
	width: 30px;
	line-height: 50px;
}

.search-form-default .search-form input:focus, 
.search-form-default .search-form input[type=email]:focus, 
.search-form-default .search-form input[type=password]:focus, 
.search-form-default .search-form input[type=search]:focus, 
.search-form-default .search-form input[type=text]:focus {
    border-color: var(--color-theme-primary) !important;
}
@media (max-width:767px) {
	.search-form input
	{
		font-size: 20px;
	}
}