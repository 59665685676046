footer {
	color: var(--global-font-color);
	background: var(--color-theme-dim-white);
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

footer .footer-top {
	padding: 80px 16px 80px;
}


footer .widget::before {
	display: none;
}

footer .widget {
	border: none;
	padding: 0;
	background-color: transparent;
}

footer .copyright-footer {
	background: var(--color-theme-primary-light);
	padding: 0;
	position: relative;
	color: var(--global-font-color);
	text-align: center;
}

footer .widget ul li {
	border: none;
	border: none;
	padding: 0.5em 0;
	margin-bottom: 0;
}

footer .widget ul li a {
	padding-left: 0;
}

footer .widget ul.wp-tag-cloud li {
	padding: 0;
	margin: 5px 5px 5px 0;
}

footer .widget ul li a.rsswidget {
	padding-left: 0;
	font-size: 18px;
	font-weight: 500;
}

footer .widget .rss-date {
	color: var(--color-theme-primary);
}

footer .wp-calendar-table th {
	background: var(--global-body-bgcolor);
}

footer .widget ul.menu li a,
footer .widget ul li a {
	border: none;
	position: relative;
}


footer .widget.widget_nav_menu ul li a {
	padding: 4px 0 4px 0;
}

footer .widget ul.menu li .sub-menu {
	padding-left: 10px;
}


footer .widget ul.menu>li>a {
	color: var(--global-font-color);
}

footer .widget ul.menu>li>a:hover {
	color: var(--color-theme-primary);
}

.footer .widget .footer-contact a {
	color: var(--global-font-color);
	transition: all .5s ease-in-out !important;
}

.footer .widget .footer-contact a:hover {
	color: var(--color-theme-primary);
}

footer.footer-one .copyright-footer .copyright a {
	color: var(--color-theme-white);
}

footer .widget ul li {
	border: none;
	border: none;
	padding: 0.5em 0;
	margin-bottom: 0;
	list-style: none;
}

footer .widget ul li a {
	padding-left: 0px;
}

footer ul li.cat-item span.post_count,
footer ul li span.achiveCount {
	float: right;
	color: var(--color-theme-primary);
	border-radius: 50px;
	width: 50px;
	height: auto;
	text-align: center;
	line-height: 20px;
	padding: 0;
	margin-top: 2px;

}

footer .widget ul.wp-tag-cloud li {
	padding: 0;
	margin: 5px 5px 5px 0;
}

footer .widget ul li a.rsswidget {
	padding-left: 0;
	font-size: 18px;
	font-weight: 500;
}

footer .widget .rss-date {
	color: var(--color-theme-primary);
}

footer .widget .calendar_wrap .wp-calendar-table {
	background: transparent;
}

footer table td#today {
	background: var(--color-theme-primary);
}

footer table td a {
	font-weight: 900;
}

footer .wp-calendar-table th {
	background: var(--footer-custom-color);
	color: var(--color-theme-primary);
	font-weight: 600;
}

.css_prefix figcaption {
	padding-right: 30px;
}

.css_prefix footer .widget ul.menu li a,
.css_prefix footer .widget ul li a {
	border: none;
	position: relative;
	text-transform: capitalize;
}

footer .widget ul.menu li a:hover::before {
	color: var(--color-theme-primary);
}


footer ul.wp-tag-cloud li {
	display: inline-block;
}

footer #menu-footer-menu li a::before,
footer .menu-footer-menu-container li a::before {
	display: none;
}

footer #menu-footer-menu li a,
footer .menu-footer-menu-container ul.menu li a {
	padding: 7px 0 7px 0;
}

.mc4wp-form-fields .newslatter {
	border-radius: var(--border-radius);
	width: 85%;
	position: relative;
}

.mc4wp-form-fields .newslatter input[type=email] {
	font-size: var(--font-size-normal);
	color: var(--color-theme-white);
	line-height: var(--button-line-height);
	background: transparent;
}

.mc4wp-form-fields .newslatter button {
	background: var(--color-theme-primary);
	width: 48px;
	height: 48px;
	color: var(--color-theme-white);
	cursor: pointer;
	font-size: 25px;
	position: absolute;
	right: 0;
	border-radius: 0 3px 3px 0;
}

.mc4wp-form-fields .newslatter button:hover {
	background: var(--color-theme-secondary);
	transition: all .5s ease;
	-moz-transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	-o-transition: all .5s ease;
}

.mc4wp-form-fields .subscribe button[type=submit] {
	width: 70px;
	font-size: 20px;
	height: 60px;
	padding: 0;
	font-weight: 400;
	border: none;
	position: absolute;
	right: 0;
}

footer .contect-gmail {
	word-break: break-all;
}

@media(max-width: 992px) {
	footer .footer-top {
		padding: 50px 16px 50px 16px;
	}
}

@media(max-width: 575px) {
	.mc4wp-form-fields .newslatter {
		width: 100%;
	}

	footer .footer-top {
		padding: 40px 16px 40px 16px;
	}
}